import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface SidebarStore {
  isOpen: boolean
  toggle: () => void
}

export const useSidebar = create(
  persist<SidebarStore>(
    set => ({
      isOpen: true,
      toggle: () => set(state => ({ isOpen: !state.isOpen }))
    }),
    {
      name: 'side-bar-store',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
