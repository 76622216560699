import { IConfig, UnleashClient } from '@unleash/proxy-client-react'

export const unleashProxyConfig: IConfig = {
  url: import.meta.env.DEV
    ? 'https://fsp-review-ems-config-126knk.staging.portal.dev.ferroamp.com/features/proxy'
    : `https://${location.hostname}/features/proxy`,
  clientKey: 'chei7pai6sieb7ohgaelip5aesei2roh7yeojohxequ1shaxaeJayei7iha6hae0',
  appName: 'production',
  environment: import.meta.env.DEV ? 'development' : 'production'
}

export const unleashClient = new UnleashClient(unleashProxyConfig)
