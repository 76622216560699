import { useHeader } from '@/store/useHeader'
import { AnimatePresence, motion } from 'framer-motion'

export function SiteHeader() {
  const { pageInfo } = useHeader()

  return (
    <>
      <header className='sticky top-0 z-40 h-[70px] w-full bg-background backdrop-blur'>
        <div className='bg-white dark:bg-white/5'>
          <div className='flex h-[70px] w-full items-center px-3 py-3 lg:px-5 lg:pl-3'>
            <AnimatePresence>
              {pageInfo && (
                <div className='flex flex-col'>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: 'easeIn', stiffness: 100 }}
                    exit={{ opacity: 0 }}
                    className='text-lg text-foreground'
                  >
                    {pageInfo?.title}
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: 'easeIn', stiffness: 100 }}
                    exit={{ opacity: 0 }}
                    className='text-xs text-foreground/50'
                  >
                    {pageInfo?.description}
                  </motion.p>
                </div>
              )}
            </AnimatePresence>

            <div className='flex flex-1 items-center justify-between space-x-2 md:justify-end'>
              <nav className='flex items-center gap-6 text-sm text-foreground'></nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
