'use client'
import { Link, useRouterState } from '@tanstack/react-router'

import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useSidebar } from '@/store/useSidebar'
import { type NavItem } from './useNav'

import { useEffect, useState } from 'react'
import { ChevronDown } from '../Icons'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './subnav-accordion'

interface SideNavProps {
  items: NavItem[]
  setOpen?: (open: boolean) => void
  className?: string
}

export function SideNav({ items, setOpen, className }: SideNavProps) {
  const router = useRouterState()
  const path = router.location.pathname
  const { isOpen } = useSidebar()
  const [openItem, setOpenItem] = useState('')
  const [lastOpenItem, setLastOpenItem] = useState('')

  useEffect(() => {
    if (isOpen) {
      setOpenItem(lastOpenItem)
    } else {
      setLastOpenItem(openItem)
      setOpenItem('')
    }
  }, [isOpen])

  return (
    <nav className='space-y-2'>
      {items.map(item =>
        item.isChildren ? (
          <Accordion
            type='single'
            collapsible
            className='space-y-2'
            key={item.title}
            value={openItem}
            onValueChange={setOpenItem}
          >
            <AccordionItem value={item.title} className='border-none'>
              <AccordionTrigger
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  'group relative flex h-12 justify-between px-4 py-2 text-base duration-200 hover:bg-muted hover:no-underline'
                )}
              >
                <div>
                  <item.Icon className={cn('h-5 w-5', item.color)} />
                </div>
                <div className={cn('absolute left-12 text-base duration-200', !isOpen && className)}>
                  {item.title}
                </div>

                {isOpen && (
                  <ChevronDown className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />
                )}
              </AccordionTrigger>
              <AccordionContent className='mt-2 space-y-4 pb-1'>
                {item.children?.map(child => (
                  <Link
                    key={child.title}
                    href={child.href}
                    onClick={() => {
                      if (setOpen) setOpen(false)
                    }}
                    className={cn(
                      buttonVariants({ variant: 'ghost' }),
                      'group relative flex h-12 justify-start gap-x-3',
                      path === child.href && 'bg-muted font-bold hover:bg-muted'
                    )}
                  >
                    <child.Icon className={cn('h-5 w-5', child.color)} />
                    <div className={cn('absolute left-12 text-base duration-200', !isOpen && className)}>
                      {child.title}
                    </div>
                  </Link>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ) : (
          <Link
            key={item.title}
            to={item.href}
            onClick={() => {
              if (setOpen) setOpen(false)
            }}
          >
            {({ isActive }) => (
              <div
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  'group relative flex h-12 justify-start text-foreground/50',
                  isActive && 'bg-transparent font-bold text-foreground hover:bg-muted'
                )}
              >
                <item.Icon className={cn('h-5 w-5', item.color)} />
                <span className={cn('absolute left-12 text-base duration-200', !isOpen && className)}>
                  {item.title}
                </span>
              </div>
            )}
          </Link>
        )
      )}
    </nav>
  )
}
