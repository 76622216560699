// type IconProps = React.HTMLAttributes<SVGElement>
import {
  RiAlertLine,
  RiArrowDropDownLine,
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiCheckLine,
  RiCheckboxCircleLine,
  RiCircleLine,
  RiCloseLine,
  RiCommunityLine,
  RiComputerLine,
  RiDashboardLine,
  RiErrorWarningLine,
  RiHome2Line,
  RiInformationLine,
  RiMoonLine,
  RiRefreshLine,
  RiSunLine,
  RiUserLine
} from '@remixicon/react'

import solarDark from './dark/solar.svg?react'
import solarLight from './light/solar.svg?react'

import batteryDark from './dark/battery.svg?react'
import batteryLight from './light/battery.svg?react'

import boltDark from './dark/bolt.svg?react'
import boltLight from './light/bolt.svg?react'

import consumptionDark from './dark/consumption.svg?react'
import consumptionLight from './light/consumption.svg?react'

import evDark from './dark/ev.svg?react'
import evLight from './light/ev.svg?react'

import { useFerroampTheme } from '@/hooks/useFerroampTheme'
import React, { FunctionComponent, SVGProps } from 'react'
import gridDark from './dark/grid.svg?react'
import fspIconNew from './fsp/icon-new.svg?react'
import gridLight from './light/grid.svg?react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ImageProps = React.ImgHTMLAttributes<any>

export const Logo = (props: ImageProps) => {
  const isTheme = useFerroampTheme()

  return (
    <>
      <img {...props} src={`/images/logo_${isTheme === 'dark' ? 'white' : 'black'}.png`}></img>
    </>
  )
}

/**
 * Shadcn defaults to lucide-react icons, these are mapping the lucide-react icon names to
 * remix-icon name to easily and fast replace them
 */
export {
  RiCheckLine as Check,
  RiArrowDropDownLine as ChevronDown,
  RiArrowDropLeftLine as ChevronLeft,
  RiArrowDropRightLine as ChevronRight,
  RiArrowDropDownLine as ChevronUp,
  RiCircleLine as Circle,
  RiMoonLine as Moon,
  RiSunLine as Sun,
  RiCloseLine as X
}

export const NotificationWarning = RiAlertLine
export const NotificationSuccess = RiCheckboxCircleLine
export const NotificationError = RiErrorWarningLine
export const NotificationInformation = RiInformationLine
export const NotificationNeutral = RiRefreshLine

export const DashboardIcon = RiDashboardLine
export const UserIcon = RiUserLine
export const PowershareIcon = RiCommunityLine
export const FacilityIcon = RiHome2Line
export const SingleBoardComputerIcon = RiComputerLine

export type FeIconType = 'solar' | 'battery' | 'bolt' | 'grid' | 'ev' | 'consumption' | 'fspIconNew'
const feIcons: {
  [key in FeIconType]: {
    dark: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
    light: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
  }
} = {
  solar: {
    dark: solarDark,
    light: solarLight
  },
  battery: {
    dark: batteryDark,
    light: batteryLight
  },
  bolt: {
    dark: boltDark,
    light: boltLight
  },
  consumption: {
    dark: consumptionDark,
    light: consumptionLight
  },
  ev: {
    dark: evDark,
    light: evLight
  },
  grid: {
    dark: gridDark,
    light: gridLight
  },
  fspIconNew: {
    light: fspIconNew,
    dark: fspIconNew
  }
}

export const FeIcon = React.forwardRef<SVGSVGElement, SVGProps<SVGSVGElement> & { type: FeIconType }>(
  // eslint-disable-next-line react/prop-types
  ({ type, ...props }, ref) => {
    const isTheme = useFerroampTheme()
    const FerroIcon = feIcons[type][isTheme]
    return <FerroIcon {...props} ref={ref} />
  }
)

FeIcon.displayName = 'FeIcon'
