import App from '@/App'
import '@/Sentry'
import '@/i18n'
import '@/index.css'
import FlagProvider from '@unleash/proxy-client-react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { registerSW } from 'virtual:pwa-register'
import { unleashClient } from './unleash'
registerSW()

const container = document.querySelector('#root')
if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <FlagProvider unleashClient={unleashClient} startClient={false}>
        <App />
      </FlagProvider>
    </StrictMode>
  )
}
