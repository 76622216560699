import { Sentry } from '@/Sentry'
import Keycloak, { KeycloakProfile } from 'keycloak-js'
import { create } from 'zustand'
import { AppConfig } from './config'

type RoleFeatures = {
  canUseSsh: boolean
  canRemapFacilitySbc: boolean
  canUseEmsConfiguration: boolean
}

interface AuthStoreProps {
  keycloak?: Keycloak
  loggedIn: boolean
  userHasNotAccess?: boolean
  created: boolean
  config?: AppConfig
  roleFeatures: RoleFeatures
  user?: KeycloakProfile
  createKeycloak: (config: AppConfig) => void
  initiateKeycloak: () => Promise<void>
}

const roleFeaturesBase: RoleFeatures = {
  canRemapFacilitySbc: false,
  canUseSsh: false,
  canUseEmsConfiguration: false
}

const createRoleFeatures = (keycloak: Keycloak, clientId: string): RoleFeatures => {
  return {
    canUseSsh: keycloak?.hasResourceRole('can-copy-ssh-command', clientId),
    canRemapFacilitySbc: keycloak?.hasRealmRole('can-remap-facility-sbc'),
    canUseEmsConfiguration: keycloak?.hasRealmRole('can-edit-facility-ems-configuration')
  }
}

export const useAuthStore = create<AuthStoreProps>((set, get) => ({
  loggedIn: false,
  keycloak: undefined,
  created: false,

  roleFeatures: {
    canUseSsh: false,
    canRemapFacilitySbc: false,
    canUseEmsConfiguration: false
  },

  createKeycloak: (config: AppConfig) => {
    const keycloak = get().keycloak

    if (config.authClientId && config.authREALM && config.authURL && !keycloak) {
      const myKeycloak = new Keycloak({
        url: config.authURL,
        realm: config.authREALM,
        clientId: config.authClientId
      })
      set(state => ({
        ...state,
        keycloak: myKeycloak,
        created: true,
        config: config
      }))
    }
  },
  initiateKeycloak: async () => {
    const keycloak = get().keycloak
    const created = get().created

    if (created && !get().loggedIn) {
      const authorized = await keycloak
        ?.init({
          checkLoginIframe: false,
          onLoad: 'login-required',
          pkceMethod: 'S256'
        })
        .catch(() => {
          keycloak.logout()
        })

      if (!authorized) {
        keycloak?.logout()
      }
      if (keycloak) {
        keycloak.onTokenExpired = () => {
          keycloak?.updateToken(-1)
        }
      }

      const user = await keycloak?.loadUserProfile()
      Sentry.setUser({
        id: user?.id,
        isFerroampEmployee: user?.email?.includes('@ferroamp')
      })

      const roleFeatures = keycloak
        ? createRoleFeatures(keycloak, get().config?.authClientId as string)
        : roleFeaturesBase

      if (!keycloak?.hasResourceRole('can-access', get().config?.authClientId)) {
        Sentry.captureMessage('Accessing fsp without "can-access"')
        set(state => ({ ...state, user, userHasNotAccess: true, roleFeatures }))
      } else {
        set(state => ({ ...state, user, loggedIn: true, roleFeatures }))
      }
    }
  }
}))
