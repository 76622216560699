import { cn } from '@/lib/utils'
import { usePageHeader } from '@/store/usePageHeader'
import { Link, LinkProps, useLocation } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'

export type PageHeaderChildPages = {
  link: LinkProps
  name: string
}[]

/**
 * Component to be used in top of header, for be used for introducing several sub-pages for a main page.
 */
export const PageHeader = () => {
  const pages = usePageHeader(({ pages }) => pages)
  const { pathname } = useLocation()
  return (
    <AnimatePresence>
      {pages && (
        <>
          <motion.div
            key='modal'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={cn(
              'sticky top-[69px] z-20 w-auto rounded-none border-b bg-card shadow-none dark:border-none dark:bg-background'
            )}
          >
            <div className='flex flex-row overflow-x-auto p-0 dark:bg-white/5'>
              <div className='mx-auto flex flex-row overflow-auto'>
                {pages
                  ? pages.map(({ link, name }, index) => {
                      return (
                        <Link
                          key={`name_${index}`}
                          {...link}
                          className='cursor-point ml-4 py-3 hover:bg-input'
                        >
                          {({ isActive }) => {
                            return (
                              <span
                                className={cn(
                                  'text-nowrap p-3 text-foreground/50',
                                  isActive &&
                                    pathname.split('/').length === link?.to?.split('/').length &&
                                    'text-foreground'
                                )}
                              >
                                {name}
                              </span>
                            )
                          }}
                        </Link>
                      )
                    })
                  : null}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

PageHeader.displayName = 'PageHeader'
